

.app {

  width: 100%;
  height: 100%;
  background: white;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  display: inline-flex
}

.app-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height:50px;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(10, 5, 57, 0.8);
  box-sizing: border-box;
  z-index: 1000;
}

.app-header-logo {
  position: absolute;
  left: 25px;
  height: 100%
}

.app-header-nav {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.app-header-nav-item {
  color: white;
  background-color: transparent;
  border: none;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  cursor: pointer;
}

.app-header-language {
  position: absolute;
  right: 25px;
  color: none;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500
}

.language-dropdown {
  position: absolute;
  right: 2.5%; /* Ensures it sticks to the right edge */
}

.dropbtn {
  background-color:  transparent;
  color: white;
  right: 25px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: fit-content;
  box-shadow:none;
  z-index: 1;
}

.menu-icon {
  display: none;  /* Hidden by default and will be shown on small screens */
  background: none;
  border: none;
  font-size: 30px; /* Icon size */
  color: white;
  cursor: pointer;
  padding: 15px; /* Increased padding for a larger touch area */
  margin: -15px; /* Negative margin to counteract the padding effect on layout */
  position: absolute; /* Positioning the icon */
  right: 2%; /* Right alignment */
  top: 2%; /* Maintain a consistent distance from the top */
  z-index: 2000; /* Ensure it is above other elements */
}

.dropdown-content button {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  background-color: rgba(10, 5, 57, 0.8);
  font-size: 20px;
}

.dropdown-content button:hover {background-color: rgba(10, 5, 57, 0.8)}

.language-dropdown:hover .dropdown-content {
  display: block;
}

.language-dropdown:hover .dropbtn {
  background-color:rgba(10, 5, 57, 0.8);
}


.app-hero{
  display: flex;
  width: 100%; 
  height: 1000px; 
  position: relative;
  background-image: var(--hero-image-url);
  background-size: cover;
  background-position: center;
  background-size: 100% 100%;


  
}



.app-hero-text-slogan {
  position: absolute;
  top: 30%; /* Adjust based on design */
  width: 100%;
  text-align: center;
  color: white;
  font-size: 4vw; /* Example of responsive font size */
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.app-hero-text-description {
  position: absolute;
  top: 40%; /* Adjust based on design */
  left: 35%;
  width: 50%;
  text-align: left;
  color: white;
  font-size: 1.5vw; /* Example of responsive font size */
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.app-hero-logos {
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  position: absolute;
  bottom: 100px;
  left: 0; /* Start from the left edge of the viewport */
  right: 0; /* Extend to the right edge of the viewport */
  width: 100%; /* Ensures the container spans the full width */
  height: fit-content;
  max-width: 100%; /* Prevents it from exceeding the viewport width */
  overflow-x: hidden; /* Hides any horizontal overflow */
}



.app-hero-logos-item {
  height: auto; /* Fixed height */
  max-width: 10%; /* Ensures logos do not exceed their container's width */
  object-fit: contain; /* Ensures the image is scaled correctly within its height */
  margin-right: 20px;
  
}


.app-services {
  width: 100%; /* Full width of its container */
  height: 1000px; /* Fixed height, but consider making it responsive */
  background: rgba(10, 5, 57, 1); /* Dark background as specified */
  display: flex; /* Flexbox to center the child carousel */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding-top: 20px; /* Add padding at the top if needed to create space below the hero section */
}

.app-services-carousel {
  margin: 0 auto;  /* Centers the carousel in the available space */
  max-width: 75%; /* Example max-width for large screens */


}

.app-services-carousel h3,
.app-services-carousel p {
  color: white; /* Reinforce white color for all text */
  font-size: 1.0vw; /* Responsive font size */
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  margin: 0; /* Resets any default margins and helps with consistent layout */
  padding: 0 10px; /* Adds horizontal padding for better readability */
}


.app-services-carousel .slick-dots li button:before {
  color: white; /* Changes the color of the dot itself */
  font-size: 12px;
  opacity: 1; /* Makes sure it is fully visible */
}

.app-services-carousel .slick-dots {
  position: absolute; /* If not already absolute, this can help manage layout */
  left: 0;
  right: 0;
  bottom: 0; /* Ensures dots are at the very bottom of the carousel container */
  width: 100%; /* Maintains full width */
  text-align: center; /* Keeps dots centered */
  padding: 10px 0; /* Vertical padding */
  background: rgba(10, 5, 57, 0.7); /* Semi-transparent dark background */
  border-radius: 5px; /* Rounded corners */
}


.app-services-carousel .slick-dots li.slick-active button:before {
  color: #fff; /* Light color for the active dot */
  font-size: 14px; /* Makes the active dot larger */
}




.app-services-carousel .slick-slide {
  position: relative; /* Keeps existing positioning */
  text-align: center; /* Ensures text is centered */
  padding-bottom: 50px; /* Adds space at the bottom for the dots */
}


.app-services-carousel .slick-slide img {
  display: block;
  width: 100%; /* Makes the image full width of the slide */
  height: auto; /* Maintains aspect ratio */
}


.app-about {
  width: 100%; /* Full width of its container */
  height: 1000px; /* Consider making height responsive or auto */
  background: rgba(10, 5, 57, 1); /* Dark background */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding-top: 20px;

  flex-direction: column;
}

.app-about-video {

  max-width: 75%; /* Adjust based on your desired visible area size */
  height: auto; /* Maintains aspect ratio */
  overflow: hidden; /* Ensures nothing outside this container is shown */
  border-radius: 0%; /* Creates a circular lens effect */
  box-shadow: rgba(10, 5, 57, 1); /* Optional: Adds a shadow outside the circle */
  display: flex;
  justify-content: center; 
  align-items: center;  

}

.app-about-title {
  margin-top: 20px; /* Adds some space between the video and the text */
  color: white; /* Reinforce white color for all text */
  font-size: 1.0vw; /* Responsive font size */
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  margin: 0; /* Resets any default margins and helps with consistent layout */
  padding: 0 10px; /* Adds horizontal padding for better readability */

  text-align: center;
}

.app-about-text {
  margin-top: 20px; /* Adds some space between the video and the text */
  color: white; /* Reinforce white color for all text */
  font-size: 1.0vw; /* Responsive font size */
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  margin: 0; /* Resets any default margins and helps with consistent layout */
  padding: 0 10px; /* Adds horizontal padding for better readability */
  max-width: 75%;
  text-align: justify;
}


.app-contact {
  width: 100%; /* Full width of its container */
  height: fit-content; /* Consider making height responsive or auto */
  background: rgba(10, 5, 57, 1); /* Dark background */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding-top: 20px;
  padding-bottom: 50px;

}

.app-contact-text {
  width: 80%;
  margin-top: 20px; /* Adds some space between the video and the text */
  color: white; /* Reinforce white color for all text */
  font-size: 1.0vw; /* Responsive font size */
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  margin: 0; /* Resets any default margins and helps with consistent layout */
  padding: 0 10px; /* Adds horizontal padding for better readability */
  max-width: 75%;
  text-align: center;
  
}

.app-contact .contact-icon {
  color: white;          /* Set icon color */
  margin-right: 10px;    /* Add some space between the icons */
  font-size: 24px;       /* Adjust icon size as necessary */
  text-decoration: none; /* Remove underline from links */
}

.app-contact .contact-icon:last-child {
  margin-right: 0; /* Remove margin from the last icon */
}


.app-contact-text p {
  margin-top: 0;
  margin-bottom: 0;
  
}


@media (max-width: 768px) {
  .menu-icon {
      display: block;
  }

  .app-header-logo {
    position: absolute;
    left: 1%;
    height: 80%
  }

  .app-header-nav {
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      display: none;
      background-color: rgba(10, 5, 57, 0.95);
  }

  .app-header-nav.open, .dropdown-content {
      display: flex;
      flex-direction: column;
  }

  .app-header-nav-item, .dropbtn {
      font-size: 18px;
      padding: 10px;
  }

  .app-hero{
    display: flex;
    width: 100%; 
    height: 300px; 
    position: relative;
    background-image: var(--hero-image-url);
    background-size: cover;
    background-position: center;
    background-size: 100% 100%;
    
  
    
  }
  
  
  
  .app-hero-text-slogan {
    position: absolute;
    top: 30%; /* Adjust based on design */
    width: 100%;
    text-align: center;
    color: white;
    font-size: 6vw; /* Example of responsive font size */
    font-family: 'Montserrat', sans-serif;
    font-weight: 100;
  }
  
  .app-hero-text-description {
    position: absolute;
    top: 40%; /* Adjust based on design */
    left: 35%;
    width: 60%;
    text-align: just;
    color: white;
    font-size: 3.5vw; /* Example of responsive font size */
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
  }
  
  .app-hero-logos {
    display: flex;
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
    position: absolute;
    bottom: 0px;

    left: 0%; /* Start from the left edge of the viewport */
    right: 0; /* Extend to the right edge of the viewport */
    width: 100%; /* Ensures the container spans the full width */
    height: fit-content;
    max-width: 100%; /* Prevents it from exceeding the viewport width */
    overflow-x: hidden; /* Hides any horizontal overflow */
  }
  
  
  
  .app-hero-logos-item {
    height: auto; /* Fixed height */
    max-width: 15%; /* Ensures logos do not exceed their container's width */
    object-fit: contain; /* Ensures the image is scaled correctly within its height */
    margin-right: 5px;
    
  }

  .app-services {
    height: 300px; /* Adjust height as needed */
    padding-top: 40%; /* Remove top padding for tighter design */
  }

  .app-services-carousel {
    max-width: 100%; /* Full width to utilize the entire screen space */
    overflow: hidden; /* Hides anything outside the bounding box */
    top: 10px;
    z-index: 1;
  }

  /* Ensuring text spans the full width of the carousel */
  .app-services-carousel h3,
  .app-services-carousel p {
    font-size: 4vw; /* Increase font size for better readability on small devices */
    padding: 0 5%; /* Adding some padding for better text spacing */
    text-align: center; /* Ensuring text is centered */
    font-family: 'Montserrat', sans-serif;
  }

  /* Full-width images settings */
  .app-services-carousel .slick-slide img {
    width: 100%;
    height: 300px; /* Fixed height to maintain aspect ratio */
    object-fit: cover; /* Cover ensures the image fills the height and width, cropping as necessary */
    object-position: center; /* Centers the image so it's 'lens' focused */
  }

  /* Hide navigation arrows */
  .slick-prev, .slick-next {
    display: none !important; /* Important might be necessary to override slick carousel default styles */
  }

  /* Styling adjustments for the dots */
  .app-services-carousel .slick-dots {
    top: 0px; /* Position dots at the bottom of the slide */
    background: transparent; /* Removing semi-transparent background for a cleaner look */
    pointer-events: none;
  }

  .app-services-carousel .slick-dots li button:before {
    font-size: 12px; /* Adjust dot size for better visibility */
  }

  .app-services-carousel .slick-dots li.slick-active button:before {
    color: #fff; /* Active dot color */
  }

  .app-about {
    width: 100%; /* Full width of its container */
    height: fit-content; /* Consider making height responsive or auto */
    background: rgba(10, 5, 57, 1); /* Dark background */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding-top: 15%;
  
    flex-direction: column;
  }
  
  .app-about-video {
display: none;  
}



  
  .app-about-text {
    margin-top: 20px; /* Adds some space between the video and the text */
    color: white; /* Reinforce white color for all text */
    font-size: 1.0vw; /* Responsive font size */
   
    font-size: 16px;
    margin: 0; /* Resets any default margins and helps with consistent layout */
    padding: 0 10px; /* Adds horizontal padding for better readability */
    max-width: 75%;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
  }
  
  
  .app-contact {
    width: 100%; /* Full width of its container */
    height: fit-content; /* Consider making height responsive or auto */
    background: rgba(10, 5, 57, 1); /* Dark background */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding-top: 20px;
    padding-bottom: 50px;
  
  }
  
  .app-contact-text {
    width: 80%;
    margin-top: 20px; /* Adds some space between the video and the text */
    color: white; /* Reinforce white color for all text */
    font-size: 16px; /* Responsive font size */
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    margin: 0; /* Resets any default margins and helps with consistent layout */
    padding: 0 10px; /* Adds horizontal padding for better readability */
    max-width: 75%;
    text-align: center;
    
  }
  
  .app-contact .contact-icon {
    color: white;          /* Set icon color */
    margin-right: 10px;    /* Add some space between the icons */
    font-size: 24px;       /* Adjust icon size as necessary */
    text-decoration: none; /* Remove underline from links */
  }
  
  .app-contact .contact-icon:last-child {
    margin-right: 0; /* Remove margin from the last icon */
  }
  
  
  .app-contact-text p {
    margin-top: 0;
    margin-bottom: 0;
    
  }
}
